.title-shadow {
  text-shadow: #222 0px 0px 5px;
}

.logo-shadow {
  filter: drop-shadow(0px 0px 1px #222);
}

.bg-video-image {
  background-image: url(media/bg-video-image.jpg);
}

:root {
  --spinner-color: #60a5fa;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner div {
  box-sizing: border-box !important;
}

.spinner > div {
  position: absolute;
  width: 136px;
  height: 136px;
  top: 32px;
  left: 32px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
  animation: spinner-animation 1.25s linear infinite;
}

.spinner > div:nth-child(2) {
  border-color: transparent;
}

.spinner > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.spinner > div:nth-child(2) div:before,
.spinner > div:nth-child(2) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 56px;
  background: var(--spinner-color);
  border-radius: 50%;
  box-shadow: 0 128px 0 0 var(--spinner-color);
}

.spinner > div:nth-child(2) div:after {
  left: -8px;
  top: 56px;
  box-shadow: 128px 0 0 0 var(--spinner-color);
}

.loader-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  background: none;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.spinner div {
  box-sizing: content-box;
}
